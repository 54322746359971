var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":"","md6":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require("@/assets/logo.png")}})])])],1),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h4 primary--text"},[_vm._v("Simule seu sistema")]),_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" Faça um orçamento sem compromisso e surpreenda-se. ")])])],1),[_c('validation-observer',{ref:"observer"},[_c('v-form',{ref:"form",staticClass:"pt-8",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Seu nome","required":"","outlined":"","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Seu e-mail","required":"","outlined":"","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) ####-####', '(##) #####-####']),expression:"['(##) ####-####', '(##) #####-####']"}],attrs:{"label":"Seu telefone","required":"","outlined":"","error-messages":errors},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.states,"item-text":"name","item-value":"uf","label":"Seu estado","required":"","outlined":"","error-messages":errors},on:{"change":_vm.searchCities},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.cities,"item-text":"name","item-value":"name","label":"Sua cidade","required":"","error-messages":errors,"outlined":"","disabled":_vm.state ? false : true},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-8",attrs:{"cols":"12","md":"12"}},[_c('v-divider',{staticClass:"primary"})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Média mensal de consumo (kWh)","required":"","outlined":"","error-messages":errors},model:{value:(_vm.monthly_average_consumption),callback:function ($$v) {_vm.monthly_average_consumption=$$v},expression:"monthly_average_consumption"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.price ? _vm.money : null),expression:"price ? money : null"}],attrs:{"label":"Preço do kWh","required":"","outlined":"","error-messages":errors},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{class:errors.length ? 'error--text' : ''},[_vm._v(" Padrão de entrada ")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.input_pattern),callback:function ($$v) {_vm.input_pattern=$$v},expression:"input_pattern"}},[_c('v-radio',{attrs:{"label":"Monofásico","value":"Monofásico"}}),_c('v-radio',{attrs:{"label":"Bifásico","value":"Bifásico"}}),_c('v-radio',{attrs:{"label":"Trifásico","value":"Trifásico"}})],1)]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{class:errors.length ? 'error--text' : ''},[_vm._v(" Tipo de telhado ")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.roof_type),callback:function ($$v) {_vm.roof_type=$$v},expression:"roof_type"}},[_c('v-radio',{attrs:{"label":"Zinco","value":"Zinco"}}),_c('v-radio',{attrs:{"label":"Telhado Colonial","value":"Telhado Colonial"}}),_c('v-radio',{attrs:{"label":"Telhado Ethernit","value":"Telhado Ethernit"}}),_c('v-radio',{attrs:{"label":"Solo","value":"Solo"}}),_c('v-radio',{attrs:{"label":"Laje","value":"Laje"}})],1)]}}])})],1)],1),_c('v-row',{staticClass:"pt-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"secondary--text",attrs:{"type":"submit","color":"primary","block":""}},[_vm._v("Simular o sistema")])],1)],1)],1)],1)]],2)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.dialogProgress),callback:function ($$v) {_vm.dialogProgress=$$v},expression:"dialogProgress"}},[_c('v-card',{attrs:{"color":"secondary","dark":""}},[_c('v-card-text',{staticClass:"pa-5"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"secondary"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center mt-6"},[_c('img',{attrs:{"src":require("@/assets/success.png")}}),_c('div',{staticClass:"text-h6 primary--text mt-4"},[_vm._v("Simulação enviada!")]),_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" Breve entraremos em contato. Obrigado! ")])])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Ok ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }