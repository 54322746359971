import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from "axios";

export interface ApiError {
  message: string;
}

export class BackendService {
  getAxiosInstance(): AxiosInstance {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_URL_API,
    });
    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config: AxiosRequestConfig) {
      return config;
    });

    // Add a response interceptor
    instance.interceptors.response.use(
      function (response: AxiosResponse) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        return Promise.reject(error);
      }
    );

    return instance;
  }
}
