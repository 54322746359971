



































































































































































































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import { mask } from "vue-the-mask";
import { VMoney } from "v-money";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";
import { required, email, numeric } from "vee-validate/dist/rules";
import { BackendService } from "@/services/BackendService";
import { State } from "@/models/State";
import { City } from "@/models/City";
import { Simulate } from "@/models/Simulate";

const backendService = new BackendService();

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Campo obrigatório",
});

extend("email", {
  ...email,
  message: "Deve ser um e-mail válido",
});

extend("numeric", {
  ...numeric,
  message: "Deve ser um número válido",
});

export default defineComponent({
  directives: { mask, money: VMoney },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    recaptcha() {
      this.$recaptchaLoaded().then(() => {
        console.log("recaptcha loaded");

        this.$recaptcha("login").then((token: string) => {
          this.onSubmit(token);
        });
      });
    },

    async validate() {
      if (
        !(await (
          this.$refs.observer as InstanceType<typeof ValidationObserver>
        ).validate())
      )
        return;

      this.recaptcha();
    },
  },
  setup() {
    const states = ref<State[]>([]);
    const cities = ref<City[]>([]);
    const valid = ref(true);
    const dialog = ref(false);
    const dialogProgress = ref(false);
    const form = ref<HTMLFormElement>();
    const observer = ref<InstanceType<typeof ValidationObserver>>();

    const name = ref<string | null>(null);
    const email = ref<string | null>(null);
    const phone = ref<string | null>(null);
    const state = ref<string | null>(null);
    const city = ref<string | null>(null);
    const monthly_average_consumption = ref<string | null>(null);
    const price = ref<string | null>(null);
    const input_pattern = ref<string | null>(null);
    const roof_type = ref<string | null>(null);

    const money = reactive({
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
      masked: false,
    });

    onMounted(async () => {
      try {
        states.value = (
          await backendService.getAxiosInstance().get("api/states")
        ).data;
      } catch (e) {
        //
      }
    });

    const searchCities = async (state: string) => {
      try {
        cities.value = (
          await backendService.getAxiosInstance().get("api/cities/" + state)
        ).data;
      } catch (e) {
        //
      }
    };

    const onSubmit = async (token: string) => {
      dialogProgress.value = true;
      try {
        await backendService
          .getAxiosInstance()
          .post<Simulate>("api/simulates", {
            name: name.value,
            email: email.value,
            phone: phone.value,
            state: state.value,
            city: city.value,
            monthly_average_consumption: monthly_average_consumption.value,
            price: price.value,
            input_pattern: input_pattern.value,
            roof_type: roof_type.value,
            "g-recaptcha-response": token,
          });
        dialogProgress.value = false;
        dialog.value = true;
        form.value?.reset();
        input_pattern.value = "x";
        roof_type.value = "x";
      } catch (e) {
        //
        dialogProgress.value = false;
      }
    };

    return {
      states,
      name,
      email,
      phone,
      state,
      city,
      cities,
      monthly_average_consumption,
      price,
      input_pattern,
      roof_type,
      money,
      valid,
      searchCities,
      onSubmit,
      form,
      dialog,
      dialogProgress,
      observer,
    };
  },
});
