import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from "vuetify/src/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: "#F7931E",
        secondary: "#202730",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
