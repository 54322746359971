










import { defineComponent } from "@vue/composition-api";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default defineComponent({
  name: "App",
  components: {
    Header,
    Footer,
  },
});
