
















import { defineComponent, ref } from "@vue/composition-api";

interface Link {
  icon: string;
  href: string;
  target: string;
}

export default defineComponent({
  name: "Header",
  setup() {
    const actionBar = ref<Link[]>([]);

    actionBar.value = [
      {
        icon: "fas fa-envelope",
        href: "mailto:contatoluminosenergiasolar@gmail.com",
        target: "_self",
      },
      {
        icon: "fas fa-phone-alt",
        href: "tel:+5573988492024",
        target: "_self",
      },
      {
        icon: "fab fa-instagram",
        href: "http://instagram.com/_u/luminosenergia/",
        target: "_blank",
      },
    ];

    return {
      actionBar,
    };
  },
});
